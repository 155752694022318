<template>
  <div class="tw-flex account">
    <account-side />
    <div class="tw-flex tw-flex-1 account__content tw-bg-bg-primary tw-overflow-y-auto tw-p-3">
      <div class="account__content__container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import AccountSide from './_subs/AccountSide/index.vue'

  /**
   * @module view - account
   */
  export default {
    name: 'Account',
    components: {
      AccountSide
    },
    beforeRouteEnter (to, from, next) {
      /**
       * Redirect an admin (who's impersonating a user) to the dashboard
       * since the Account view is only for the users themselves.
       */
      const impersonate = store.getters['auth/getImpersonate']
      if (impersonate) {
        next({
          name: 'Dashboard'
        })
      } else {
        next()
      }
    }
  }
</script>

<style lang="scss" scoped>

  .account {
    height: calc(100vh - 55px);

    &__content {
      overflow-y: auto;

      &__container {
        width: 420px;
        margin: 170px auto 0 auto;
      }
    }

    @media only screen and (max-width: 857px) {
      flex-direction: column;
      height: 100%;
      overflow-y: scroll !important;

      &__content {
        display: block;
        overflow-y: initial;

        &__container {
          padding-top: 16px;
          width: 100%;
          margin: 0;
        }
      }
    }
  }

</style>
